
<div class="footer-links-container">
  <div class="footer-links">
    
    <ul class="col" *ngFor="let menu of navLinks">
      <li class="row" [ngClass]="{'show':menu.row}" style="max-height: 58px;">
        <a (click)="menu.row = menu.row ? false : true" class="accordion-toggle"></a>
        <dl>
          <dt><a [routerLink]="menu.url">{{menu.text}}</a></dt>
          <dd *ngFor="let subMenu of menu['sub-menu']"><a [routerLink]="subMenu.url">{{subMenu.text}}</a></dd>
        </dl>
      </li>
    </ul>
    
    <ul class="col">
      <li class="row" style="max-height: 58px;" [ngClass]="{'show':row1}">
        <a class="accordion-toggle" (click)="row1 = !row1"></a>
        <dl>
          <dt><a routerLink="/FIFA-2022" target="">FIFA 2022</a></dt>
          <dt><a target="">In The News</a></dt>
          <dd><a routerLink="/press-coverages" target="">Press Coverages</a></dd>
          <dd><a routerLink="/tech-testimonials" target="">Tech Testimonials</a></dd>
          <dd><a routerLink="/offers-promotions" target="">Offers/Promotions</a></dd>
        </dl>
      </li>
      <li class="row" style="max-height: 58px;" [ngClass]="{'show':row2}">
        <a class="accordion-toggle" (click)="row2 = !row2"></a>
        <dl>
          <dt><a routerLink="/about-us" target="">About Us</a></dt>
          <dt><a routerLink="/terms-and-conditions/" target="">Terms And Conditions</a></dt>
          <dt><a routerLink="/contact-us" target="">Support</a></dt>
          <dd><a routerLink="/contact-us" target="">Contact Us</a></dd>
        </dl>
      </li>
    </ul>
  </div>
</div>