import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  currentRoute: any;
  environment = environment;

  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url.split('?')[0];
    // console.log(this.currentRoute);

    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //     // Show loading indicator
      // }
      
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.document?.querySelector('body')?.scrollTo(0,0)
        // console.log(this.currentRoute)
      }

      // if (event instanceof NavigationError) {
      //     // Hide loading indicator

      //     // Present error to user
      //     console.log(event.error);
      // }
    });
    if (this.currentRoute.includes('/press-coverages/page/')){
      this.router.navigate(['/press-coverages']);
    } else {
      this.redirect()
    }
  }

  redirect() {
    console.log(this.currentRoute);
  }

}
