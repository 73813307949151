<!-- <marquee *ngIf="currentRoute == '/'" class="dummy" onmouseover="this.stop();" onmouseout="this.start();">
    <a routerLink="/v/65U7H">New Launch- 4K 120Hz Native Panel Qled TV</a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a routerLink="/v/55A7H">New Launch-"Tornado 2.0" 102 watts 6 Speaker system sound by Jbl</a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a routerLink="/c/laser-tv">NOW AVAILABLE THE 120 INCH HISENSE
        <img [src]="environment.baseApiUrl + '/media/pages/home/laser-tv-marquee.webp'" alt="Laser TV Logo"> IN
        INDIA.
    </a>
</marquee> -->

<app-header [ngClass]="{'m-top-45':currentRoute == '/'}" [top]="currentRoute == '/'"></app-header>
<!-- <div class="header-margin"></div> -->
<router-outlet></router-outlet>
<footer>
    <!-- <app-social-links-footer></app-social-links-footer> -->
    <!-- <app-footer></app-footer> -->
    <app-footer-v2></app-footer-v2>
    <!-- <app-links-footer></app-links-footer> -->
</footer>