import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-marketing-video',
  templateUrl: './marketing-video.component.html',
  styleUrls: ['./marketing-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketingVideoComponent implements OnInit {

  mediaBaseURL = 'https://hisense-production.s3.ap-south-1.amazonaws.com/media/pages/campaigns/super-brand-days-2023/media/';

  baseUrl = environment.baseApiUrl;
  image1 = `${environment.baseApiUrl}/media/pages/home/Hisense-UEFA-Euro-Cup-2020.webp`
  image2 = `${environment.baseApiUrl}/media/pages/home/Hisense-UEFA-Euro-Cup-2020-m.webp`
  image3 = `${environment.baseApiUrl}/media/pages/home/Hisense-India-Commercial.webp`
  video1 = `${this.mediaBaseURL}slider-2-slide-2-video.mp4`;

  playVideo = false;
  playVideo1 = false;

  constructor() { }

  ngOnInit(): void {
  }

}
