<div class="social-media-container">
    <a class="logo" href="https://hisense-apis.bloginguru.com/media/common/images/hisense-logo.svg">Hisense International</a>
    <div class="social-media">
        <a class="social-btn fb" href="https://www.facebook.com/HisenseIndiaOfficial/" target="_blank" rel="noopener">Facebook</a>
        <a class="social-btn twitter" href="https://twitter.com/Hisense_IND" target="_blank" rel="noopener">Twitter</a>
        <a class="social-btn ig" href="https://www.instagram.com/hisenseindiaofficial/" target="_blank"
            rel="noopener">Instagram</a>
        <a class="social-btn youtube" href="https://www.youtube.com/channel/UCuq2S-nKt0dL84SjoT-35YA" target="_blank"
            rel="noopener">Youtube</a>
        <!-- <a class="social-btn linkedin" href="https://www.linkedin.com/company/hisenseinternational/" target="_blank"
            rel="noopener">linkedin</a> -->
    </div>
</div>