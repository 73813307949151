<div class="product-listing">
    <div class="product-listing-inner" style="flex-wrap: wrap !important;width: 96% !important;margin: auto !important;">
        <div class="product-listing-tab" style="padding: 32px 12px !important;" *ngFor="let prod of products" [routerLink]="prod.url">
            <a class="product-listing-img" style="height: 280px;margin: 0 20px;">
                <img [src]="prod.image" style="max-height: 250px;max-width: 100% !important;" [alt]="'View all products in ' + prod.text + ' category'">
            </a>
            <div style="margin-top: auto;">
                <div class="product-listing-tab-content">
                    <h4 style="font-size: 22px;">{{prod.text}}</h4>
                    <!-- <div class="product-listing-description"></div> -->
                </div>
                <div><a class="btn-hisense-v2" target="_self">More Detail</a></div>
            </div>
        </div>
    </div>
</div>