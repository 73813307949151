<h2 class="sub-title">
    <p>Discover our <strong>series</strong></p>
</h2>
<ul class="series-container">
    <li class="col-full">

        <div class="top">
            <h3>TV</h3>
        </div>

        <!-- <div class="banner">
            <div class="img" style="left: 0%;">
                <img class="lazy desktop-only entered loaded" alt="ULED 8K TV U80G" data-ll-status="loaded"
                    src="assets/about-hisense/overview/hisense-uled-8k-tv-u80g.jpg">

                <img class="lazy mobile-only" alt="ULED 8K TV U80G"
                    src="assets/about-hisense/overview/hisense-uled-8k-tv-u80g-m.jpg">
            </div>
        </div> -->

        <div class="main-visual slider slick-initialized slick-slider slick-dotted">
            <div class="slick-list draggable" style="overflow: visible !important;">
                <div class="slick-track">
                    <div class="swiper laserTvSwiper" style="overflow: visible !important;">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let slide of slides;let i = index">
                                <a style="width: 100%;" class="slide__link" [href]="slide.click_link">
                                    <picture>
                                        <source media="(min-width:768px)" [srcset]="slide.desktop_image_full_url">
                                        <img [src]="slide.mobile_image_full_url" style="width: 100%;" class="banner"
                                            class="ls-bg" [alt]="'Series slide ' + (i + 1)">
                                    </picture>
                                </a>
                                <div class="content" style="text-align: left;">
                                    <div class="item ">
                                        <div class="new">New</div>
                        
                                        <p>{{slide.name}}</p>
                        
                                        <div class="btn-container">
                                            <a class="btn-more" [href]="slide.click_link" rel="">More</a>
                        
                                            <a routerLink="/c/tv" rel="">View all</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>

    </li>
</ul>
<!-- <ul class="series-container">
    <li class="col-full">

        <div class="top">
            <h3>Refrigerator</h3>
        </div>

        <div class="content">
            <div class="item ">
                <div class="new">New</div>

                <p>{{ 'Refrigerators' | uppercase}}</p>

                <div class="btn-container">
                    <a class="btn-more" routerLink="/v/RS564N4SU" rel="">More</a>

                    <a routerLink="/c/refrigerator" rel="">View all</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <a class="img" routerLink="/v/RS564N4SU">
                <img class="lazy desktop-only" alt="RC-68WC Ice &amp; Water Dispenser 583L Refrigerator"
                    src="assets/about-hisense/overview/REF-DESKTOP.webp">

                <img class="lazy mobile-only" alt="RC-68WC Ice &amp; Water Dispenser 583L Refrigerator"
                    src="assets/about-hisense/overview/REF-Mobile.webp">
            </a>
        </div>

    </li>
</ul> -->

<!-- <ul class="series-container">
    <li class="col-full"
        style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">

        <div class="top">
            <h3>Laser TV</h3>
            <nav>
                <a href="javascript:void(0);" class="active">Laser TV</a>
                <a href="javascript:void(0);" class="">Laser Cinema</a>
            </nav>
        </div>

        <div class="content">
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>88L5V Sonic Screen Laser TV</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/laser-tv/88l5v-sonic-screen-laser-tv"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/laser-tv-series/" target="_self" rel="">View all Laser TV</a>
                </div>
            </div>
            <div class="item" style="opacity: 1; transform: translateX(0px);">
                <div class="new">New</div>

                <p>120L5 Ultra Short Throw Laser Cinema</p>

                <div class="btn-container">
                    <a class="btn-more"
                        href="https://global.hisense.com/laser-cinema/120l5-ultra-short-throw-laser-cinema/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/laser-cinema/" target="_self" rel="">View all Laser Cinema</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="img active" style="left: -100%;">
                <img class="lazy desktop-only entered loaded"
                    data-src="https://global.hisense.com/dam/jcr:fb7a9ffe-0c68-4d3a-90f6-95ba7396b21f/hisense-88l5v-sonic-screen-laser-tv.jpg"
                    alt="Laser TV 88L5V Sonic Screen" data-ll-status="loaded"
                    src="https://global.hisense.com/dam/jcr:fb7a9ffe-0c68-4d3a-90f6-95ba7396b21f/hisense-88l5v-sonic-screen-laser-tv.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:2911e29a-3bfd-4536-904d-689192502d54/hisense-88l5v-sonic-screen-laser-tv-m.jpg"
                    alt="Laser TV 88L5V Sonic Screen"
                    src="https://global.hisense.com/dam/jcr:2911e29a-3bfd-4536-904d-689192502d54/hisense-88l5v-sonic-screen-laser-tv-m.jpg">
            </div>
            <div class="img" style="left: 0%;">
                <img class="lazy desktop-only entered loaded"
                    data-src="https://global.hisense.com/dam/jcr:3fcfa2b7-ca09-45b9-8075-91942d514ce5/hisense-120l5-ultra-short-throw-laser-cinema.jpg"
                    alt="Laser Cinema 120L5 Ultra Short Throw" data-ll-status="loaded"
                    src="https://global.hisense.com/dam/jcr:3fcfa2b7-ca09-45b9-8075-91942d514ce5/hisense-120l5-ultra-short-throw-laser-cinema.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:1cb000f7-112e-4643-a541-ef51b17b3dcc/hisense-120l5-ultra-short-throw-laser-cinema-m.jpg"
                    alt="Laser Cinema 120L5 Ultra Short Throw"
                    src="https://global.hisense.com/dam/jcr:1cb000f7-112e-4643-a541-ef51b17b3dcc/hisense-120l5-ultra-short-throw-laser-cinema-m.jpg">
            </div>
        </div>

        <a class="btn-arrow left" href="javascript:void(0);">&lt;</a>
        <a class="btn-arrow right" href="javascript:void(0);">&gt;</a>
    </li>
</ul> -->
<!-- <ul class="series-container">
    <li class="col-full">

        <div class="top">
            <h3>Soundbar</h3>
            <nav>
            </nav>
        </div>

        <div class="content">
            <div class="item ">
                <div class="new">New</div>

                <p>2.1CH Soundbar with Wireless Subwoofer HS218</p>

                <div class="btn-container">
                    <a class="btn-more"
                        href="https://global.hisense.com/soundbar/2-1ch-soundbar-with-wireless-subwoofer-hs218/"
                        target="_blank" rel="">More</a>

                    <a href="https://global.hisense.com/soundbar-series/" target="_blank" rel="">View all Soundbar</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="img" style="left: 0%;">
                <img class="lazy desktop-only entered loaded"
                    data-src="https://global.hisense.com/dam/jcr:e156dd5d-b5ec-4289-a2cc-f0218e97b5de/hisense-2-1ch-soundbar-with-wireless-subwoofer-hs218.jpg"
                    alt="2.1CH Soundbar with Wireless Subwoofer HS218" data-ll-status="loaded"
                    src="https://global.hisense.com/dam/jcr:e156dd5d-b5ec-4289-a2cc-f0218e97b5de/hisense-2-1ch-soundbar-with-wireless-subwoofer-hs218.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:eeba755b-005b-4169-b4c8-deffa771a364/hisense-2-1ch-soundbar-with-wireless-subwoofer-hs218-m.jpg"
                    alt="2.1CH Soundbar with Wireless Subwoofer HS218"
                    src="https://global.hisense.com/dam/jcr:eeba755b-005b-4169-b4c8-deffa771a364/hisense-2-1ch-soundbar-with-wireless-subwoofer-hs218-m.jpg">
            </div>
        </div>

    </li>
</ul> -->
<!-- <ul class="series-container">
    <li class="col-full"
        style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">

        <div class="top">
            <h3>Home Appliances</h3>
            <nav class="has-scroll"
                style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); transform: translateX(-369px);">
                <a href="javascript:void(0);" class="active">Refrigerator</a>
                <a href="javascript:void(0);" class="">Chest Freezer</a>
                <a href="javascript:void(0);" class="">Wine Cabinet</a>
                <a href="javascript:void(0);" class="">Laundry</a>
                <a href="javascript:void(0);" class="">Dishwasher</a>
            </nav>
        </div>

        <div class="content">
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>585L Cross Door Refrigerator RC-68WC</p>

                <div class="btn-container">
                    <a class="btn-more"
                        href="https://global.hisense.com/refrigerator/585l-cross-door-refrigerator-rc-68wc/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/refrigerator-series/" target="_self" rel="">View all
                        Refrigerator</a>
                </div>
            </div>
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>198L Chest Freezer BD-199</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/chest-freezer/198l-chest-freezer-bd-199/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/chest-freezer-series/" target="_self" rel="">View all Chest
                        Freezer</a>
                </div>
            </div>
            <div class="item" style="opacity: 1; transform: translateX(0px);">
                <div class="new">New</div>

                <p>46 Bottle Built-in Wine Cooler JC-128YA</p>

                <div class="btn-container">
                    <a class="btn-more"
                        href="https://global.hisense.com/wine-cabinet/46-bottle-built-in-wine-cabinet-jc-128ya/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/wine-cabinet-series/" target="_self" rel="">View all Wine
                        Cabinet</a>
                </div>
            </div>
            <div class="item hidden" style="opacity: 1; transform: translateX(0px);">
                <div class="new">New</div>

                <p>10kg Front Load Washer and Dryer WDQY1014EVJM</p>

                <div class="btn-container">
                    <a class="btn-more"
                        href="https://global.hisense.com/laundry/10kg-front-load-washer-and-dryer-wdqy1014evjm/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/laundry-series/" target="_self" rel="">View all Laundry</a>
                </div>
            </div>
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>Integrated Dishwasher HV672C60UK</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/dishwasher/integrated-dishwasher-hv672c60uk/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/dishwasher-series/" target="_self" rel="">View all
                        Dishwasher</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="img active" style="left: -100%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:e34ea6d7-b4a6-4c16-97a5-127972fa3ad3/hisense-585l-cross-door-refrigerator-rc-68wc.jpg"
                    alt="RC-68WC Ice &amp; Water Dispenser 583L Refrigerator"
                    src="https://global.hisense.com/dam/jcr:e34ea6d7-b4a6-4c16-97a5-127972fa3ad3/hisense-585l-cross-door-refrigerator-rc-68wc.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:f387e455-f1b8-4fd2-8469-74d7f81c32d5/hisense-585l-cross-door-refrigerator-rc-68wc-m.jpg"
                    alt="RC-68WC Ice &amp; Water Dispenser 583L Refrigerator"
                    src="https://global.hisense.com/dam/jcr:f387e455-f1b8-4fd2-8469-74d7f81c32d5/hisense-585l-cross-door-refrigerator-rc-68wc-m.jpg">
            </div>
            <div class="img" style="left: -100%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:421a77c3-8359-4988-a250-f62adde6bf45/hisense-198l-chest-freezer-bd-199.jpg"
                    alt="Hisense BD199 Chest Freezer 198L"
                    src="https://global.hisense.com/dam/jcr:421a77c3-8359-4988-a250-f62adde6bf45/hisense-198l-chest-freezer-bd-199.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:9e4cb467-caa1-4b86-9bf0-668cc1cde893/hisense-198l-chest-freezer-bd-199-m.jpg"
                    alt="Hisense BD199 Chest Freezer 198L"
                    src="https://global.hisense.com/dam/jcr:9e4cb467-caa1-4b86-9bf0-668cc1cde893/hisense-198l-chest-freezer-bd-199-m.jpg">
            </div>
            <div class="img" style="left: 0%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:bc4e0a3f-6ad9-475d-96d8-9692a031a93d/hisense-140-can-beverage-cabinet-jc-138yb.jpg"
                    alt="140 Can Beverage Cabinet JC-138YB"
                    src="https://global.hisense.com/dam/jcr:bc4e0a3f-6ad9-475d-96d8-9692a031a93d/hisense-140-can-beverage-cabinet-jc-138yb.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:492a1f91-575f-45fd-a02a-7a9bab52516f/hisense-140-can-beverage-cabinet-jc-138yb-m.jpg"
                    alt="140 Can Beverage Cabinet JC-138YB"
                    src="https://global.hisense.com/dam/jcr:492a1f91-575f-45fd-a02a-7a9bab52516f/hisense-140-can-beverage-cabinet-jc-138yb-m.jpg">
            </div>
            <div class="img active" style="left: 100%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:cb12acbd-6102-4260-ad56-cb458ddbb645/hisense-10kg-front-load-washer-and-dryer-wdqy1014evjm.jpg"
                    alt="Hisense Front Load Washer and Dryer WDQY1014EVJM"
                    src="https://global.hisense.com/dam/jcr:cb12acbd-6102-4260-ad56-cb458ddbb645/hisense-10kg-front-load-washer-and-dryer-wdqy1014evjm.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:d9441378-6b34-4897-9ed8-99742e3707d2/hisense-10kg-front-load-washer-and-dryer-wdqy1014evjm-m.jpg"
                    alt="Hisense Front Load Washer and Dryer WDQY1014EVJM"
                    src="https://global.hisense.com/dam/jcr:d9441378-6b34-4897-9ed8-99742e3707d2/hisense-10kg-front-load-washer-and-dryer-wdqy1014evjm-m.jpg">
            </div>
            <div class="img active" style="left: 100%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:d9df7dcb-c394-4aa4-95f1-5b273ccaa621/hisense-integrated-dishwasher-hv672c60uk.jpg"
                    alt="Hisense Dishwasher HV672c60uk"
                    src="https://global.hisense.com/dam/jcr:d9df7dcb-c394-4aa4-95f1-5b273ccaa621/hisense-integrated-dishwasher-hv672c60uk.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:bf5b1f15-98a0-4d5e-9a3f-fcd0508b7d8b/hisense-integrated-dishwasher-hv672c60uk-m.jpg"
                    alt="Hisense Dishwasher HV672c60uk"
                    src="https://global.hisense.com/dam/jcr:bf5b1f15-98a0-4d5e-9a3f-fcd0508b7d8b/hisense-integrated-dishwasher-hv672c60uk-m.jpg">
            </div>
        </div>

        <a class="btn-arrow left" href="javascript:void(0);">&lt;</a>
        <a class="btn-arrow right" href="javascript:void(0);">&gt;</a>
    </li>
</ul> -->
<ul class="series-container">
    <li class="col-full">

        <div class="top">
            <h3>Air Conditioner</h3>
        </div>

        <div class="content">
            <div class="item ">
                <div class="new">New</div>

                <p>{{ 'Intellipro' }}</p>

                <div class="btn-container">
                    <a class="btn-more" routerLink="/c/Intelli-PRO" target="_self" rel="">More</a>

                    <a routerLink="/c/air-conditioner" target="_self" rel="">View all</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <a class="img" style="left: 0%;" routerLink="/c/Intelli-PRO">
                <!-- <img class="lazy desktop-only" alt="Hisense Silentium Pro Air Conditioner"
                    src="assets/about-hisense/overview/hisense-silentium-pro-split-air-conditioner.jpg">

                <img class="lazy mobile-only" alt="Hisense Silentium Pro Air Conditioner"
                    src="assets/about-hisense/overview/hisense-silentium-pro-split-air-conditioner-m.jpg"> -->
                <img class="lazy" alt="Hisense Silentium Pro Air Conditioner"
                    src="assets/ac-home-page.webp">
            </a>
        </div>

    </li>
</ul>
<!-- <ul class="series-container">
    <li class="col-full"
        style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">

        <div class="top">
            <h3>Kitchen</h3>
            <nav>
                <a href="javascript:void(0);" class="active">Hob</a>
                <a href="javascript:void(0);" class="">Oven</a>
                <a href="javascript:void(0);" class="">Microwave</a>
            </nav>
        </div>

        <div class="content">
            <div class="item" style="opacity: 1; transform: translateX(0px);">
                <div class="new">New</div>

                <p>Induction Hob I6433CB</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/hob/induction-hob-i6433cb/" target="_self"
                        rel="">More</a>

                    <a href="https://global.hisense.com/hob-series/" target="_self" rel="">View all Hob</a>
                </div>
            </div>
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>Built-in Combined Steam Oven BS5545AG</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/oven/built-in-combined-steam-oven-bs5545ag/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/oven-series/" target="_self" rel="">View all Oven</a>
                </div>
            </div>
            <div class="item hidden" style="opacity: 0; transform: translateX(100px);">
                <div class="new">New</div>

                <p>Freestanding Microwave H28MOBS8HGUK</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/microwave/freestanding-microwave-h28mobs8hguk/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/microwave-series/" target="_self" rel="">View all Microwave</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="img active" style="left: -0%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:fc9e7023-9484-4674-89cc-a7f9edd9446a/hisense-induction-hob-i6433cb.jpg"
                    alt="Hisense Induction Hob i6433cb"
                    src="https://global.hisense.com/dam/jcr:fc9e7023-9484-4674-89cc-a7f9edd9446a/hisense-induction-hob-i6433cb.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:ea419ffa-2e4d-41a3-a425-854c15f36568/hisense-induction-hob-i6433cb-m.jpg"
                    alt="Hisense Induction Hob i6433cb"
                    src="https://global.hisense.com/dam/jcr:ea419ffa-2e4d-41a3-a425-854c15f36568/hisense-induction-hob-i6433cb-m.jpg">
            </div>
            <div class="img active" style="left: 0%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:3eb6ecef-5001-480f-96a9-ea2b5d54e25e/hisense-built-in-combined-steam-oven-bs5545ag.jpg"
                    alt="Built-in Combined Steam Oven BS5545AG"
                    src="https://global.hisense.com/dam/jcr:3eb6ecef-5001-480f-96a9-ea2b5d54e25e/hisense-built-in-combined-steam-oven-bs5545ag.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:72dcd0e6-3fcc-4cae-9af4-99e8021936a7/hisense-built-in-combined-steam-oven-bs5545ag-m.jpg"
                    alt="Built-in Combined Steam Oven BS5545AG"
                    src="https://global.hisense.com/dam/jcr:72dcd0e6-3fcc-4cae-9af4-99e8021936a7/hisense-built-in-combined-steam-oven-bs5545ag-m.jpg">
            </div>
            <div class="img active" style="left: 100%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:8d5fbba4-882d-48ab-82a6-8c314fb72712/hisense-freestanding-microwave-h28mobs8hguk.jpg"
                    alt="Freestanding Microwave H28MOBS8HGUK"
                    src="https://global.hisense.com/dam/jcr:8d5fbba4-882d-48ab-82a6-8c314fb72712/hisense-freestanding-microwave-h28mobs8hguk.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:bafe90e8-3353-4cb7-9035-689e53d3218c/hisense-freestanding-microwave-h28mobs8hguk-m.jpg"
                    alt="Freestanding Microwave H28MOBS8HGUK"
                    src="https://global.hisense.com/dam/jcr:bafe90e8-3353-4cb7-9035-689e53d3218c/hisense-freestanding-microwave-h28mobs8hguk-m.jpg">
            </div>
        </div>

        <a class="btn-arrow left" href="javascript:void(0);">&lt;</a>
        <a class="btn-arrow right" href="javascript:void(0);">&gt;</a>
    </li>
</ul> -->
<!-- <ul class="series-container">
    <li class="col-full">

        <div class="top">
            <h3>MOBILE</h3>
            <nav>
            </nav>
        </div>

        <div class="content">
            <div class="item ">
                <div class="new">New</div>

                <p>Hisense Infinity H60 5G</p>

                <div class="btn-container">
                    <a class="btn-more" href="https://global.hisense.com/smartphone/hisense-infinity-h60-5g/"
                        target="_self" rel="">More</a>

                    <a href="https://global.hisense.com/smartphone-series/" target="_self" rel="">View all
                        Smartphone</a>
                </div>
            </div>
        </div>

        <div class="banner">
            <div class="img" style="left: 0%;">
                <img class="lazy desktop-only"
                    data-src="https://global.hisense.com/dam/jcr:14849e1d-6bfa-42e5-b448-8263b789594b/homepage_hisense-infinity-h60-5g-list.jpg"
                    alt="Hisense Infinity H60 5G"
                    src="https://global.hisense.com/dam/jcr:14849e1d-6bfa-42e5-b448-8263b789594b/homepage_hisense-infinity-h60-5g-list.jpg">

                <img class="lazy mobile-only"
                    data-src="https://global.hisense.com/dam/jcr:b10f40e2-4c8a-44e1-b867-413a04da0512/homepage_hisense-infinity-h60-5g-list_m.jpg"
                    alt="Hisense Infinity H60 5G"
                    src="https://global.hisense.com/dam/jcr:b10f40e2-4c8a-44e1-b867-413a04da0512/homepage_hisense-infinity-h60-5g-list_m.jpg">
            </div>
        </div>

    </li>
</ul> -->