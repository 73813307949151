<h2 class="sub-title">About <strong>Hisense</strong></h2>
<div class="about-container highlight slick-initialized slick-slider">
    <div class="slick-list draggable">
        <div class="slick-track">
            <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false">
                <div>
                    <div class="slide-item">
                        <div class="content">
                            <p>In <span>Love</span><br>
                                with Technology
                            </p>
                            <a class="btn-more" routerLink="/about-hisense/overview/" target="_self"
                                rel="" tabindex="0">Explore More</a>
                        </div>

                        <div class="banner">
                            <img class="desktop-only" src="assets/images/slider1.jpg"
                                alt="About Hisense Overview Image">
                            <img class="mobile-only" src="assets/images/m_slider.jpg"
                                alt="About Hisense Overview Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="about-container highlight slick-initialized slick-slider">

    <div class="grid three-col">
        <div class="slick-list draggable">
            <!-- <div class="slick-track"> -->
                <!-- <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"> -->
                    <!-- <div> -->
                        <div class="slide-item">
                            <div class="content">
                                <p>Active in <br><span>Social Public Welfare</span>
                                </p>
                                <a class="btn-more" routerLink="/about-hisense/csr"
                                    target="_self" rel="" tabindex="0">Explore More</a>
                            </div>

                            <div class="banner public-welfare">
                                <div class="desktop-only" src="assets/about-hisense/overview/csr.jpg"
                                    alt="About Hisense Overview Image"></div>
                                <div class="mobile-only" src="assets/about-hisense/overview/csr.jpg"
                                    alt="About Hisense Overview Image"></div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
        <div class="slick-list draggable">
            <!-- <div class="slick-track">
                <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false">
                    <div> -->
                        <div class="slide-item">
                            <div class="content">
                                <p><span>Innovation</span><br>
                                    Through Experience
                                </p>
                                <a class="btn-more" routerLink="/about-hisense/technology"
                                    target="_self" rel="" tabindex="0">Explore More</a>
                            </div>

                            <div class="banner technology">
                                <div class="desktop-only" src="assets/about-hisense/overview/m_technology.jpg"
                                    alt="About Hisense Overview Image"></div>
                                <div class="mobile-only" src="assets/about-hisense/overview/m_technology.jpg"
                                    alt="About Hisense Overview Image"></div>
                            </div>
                        <!-- </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="slick-list draggable">
            <!-- <div class="slick-track">
                <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false">
                    <div> -->
                        <div class="slide-item">
                            <div class="content">
                                <p>Everything We Do Make your  <span>Life Simpler</span>
                                </p>
                                <a class="btn-more" routerLink="/about-hisense/values"
                                    target="_self" rel="" tabindex="0">Explore More</a>
                            </div>

                            <div class="banner values">
                                <div class="desktop-only" src="assets/about-hisense/overview/values.jpg"
                                    alt="About Hisense Overview Image"></div>
                                <div class="mobile-only" src="assets/about-hisense/overview/values.jpg"
                                    alt="About Hisense Overview Image"></div>
                            </div>
                        </div>
                    <!-- </div>
                </div>
            </div> -->
        </div>
    </div>
</div>