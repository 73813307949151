<header #header [ngClass]="{'menu-in': mobileNavToggle,'top-45': top}">
    <div class="overlay" (click)="mobileNavToggle = false"></div>
    <nav class="main-nav">
        <a class="logo" routerLink="/">Hisense International</a>
        <div class="navbar" id="navbar">
            <div class="navigations">
                <ul class="left">
                    <li *ngFor="let obj of navLinks">
                        <a [routerLink]="obj.url">{{obj.text}}</a>
                        <div style="width: 25%;" (click)="obj.subDropdownToggle = true">
                            <a style="margin-left: auto !important;" class="toggle-dropdown"
                                id="toggle-dropdown-tv-and-audio" aria-haspopup="true" aria-expanded="false"
                                aria-label="Toggle dropdown"></a>
                        </div>
                        <div [ngClass]="{'menu-in': obj.subDropdownToggle}" class="dropdown-menu"
                            aria-labelledby="toggle-dropdown-tv-and-audio">
                            <button (click)="obj.subDropdownToggle = false" class="mobile-back" type="button"
                                aria-label="Toggle sub navigation">
                                <span class="navbar-back-icon"></span>
                            </button>
                            <div class="section-title">{{obj.text}}</div>
                            <div class="sub-menu" style="background-color: white;">
                                <a [routerLink]="subLink?.url" *ngFor="let subLink of obj['sub-menu']"
                                    style="width: 230px !important;">
                                    <span class="thumbnail" style="overflow: hidden;">
                                        <div class="img">
                                            <img [src]="subLink.image" [alt]="subLink.text" [ngStyle]="{'height': subLink.isRefgrigrator ? '126px': 'auto'}"
                                                style="object-fit: contain;max-height: 180px;min-width: 170px;">
                                        </div>
                                    </span>
                                    <span class="title">{{subLink.text}}</span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <!-- <li>
                        <a routerLink="/new-launches">New Launches</a>
                    </li> -->
                    <!-- <li>
                        <a [routerLink]="'/new-launches'">New Launches</a>
                        <div style="width: 25%;" (click)="newLaunchesDropDownToggle = true">
                            <a style="margin-left: auto !important;" class="toggle-dropdown"
                                id="toggle-dropdown-tv-and-audio" aria-haspopup="true" aria-expanded="false"
                                aria-label="Toggle dropdown"></a>
                        </div>
                        <div [ngClass]="{'menu-in': newLaunchesDropDownToggle}" class="dropdown-menu"
                            aria-labelledby="toggle-dropdown-tv-and-audio">
                            <button (click)="newLaunchesDropDownToggle = false" class="mobile-back" type="button"
                                aria-label="Toggle sub navigation">
                                <span class="navbar-back-icon"></span>
                            </button>
                            <div class="section-title">New Launches</div>
                            <div class="sub-menu" style="background-color: white;">
                                <a [routerLink]="subLink?.url" *ngFor="let subLink of obj['sub-menu']"
                                    style="width: 200px !important;">
                                    <span class="thumbnail" style="overflow: hidden;">
                                        <div class="img">
                                            <img [src]="subLink.image" [alt]="New Launches"
                                                style="object-fit: contain;max-height: 180px;min-width: 170px;">
                                        </div>
                                    </span>
                                    <span class="title">New Launches</span>
                                </a>
                            </div>
                        </div>
                    </li> -->
                </ul>
                <ul class="right">
                    <!-- <li>
                        <a routerLink="/FIFA-2022">FIFA 2022</a>
                    </li> -->
                    <!-- <li>
                        <a routerLink="/super-brand-days">Super Brand Days</a>
                    </li> -->
                    <!-- <li class="in-the-news dropdown">
                        <a class="dropbtn">In The News</a>
                        <a (click)="newsSubDropdownToggle = true" class="toggle-dropdown"
                            id="toggle-dropdown-tv-and-audio" aria-haspopup="true" aria-expanded="false"
                            aria-label="Toggle dropdown"></a>
                        <div [ngClass]="{'menu-in': newsSubDropdownToggle}" class="dropdown-menu"
                            aria-labelledby="toggle-dropdown-tv-and-audio">
                            <button (click)="newsSubDropdownToggle = false" class="mobile-back" type="button"
                                aria-label="Toggle sub navigation">
                                <span class="navbar-back-icon"></span>
                            </button>
                            <div class="section-title">In The News</div>
                            <div class="sub-menu ">
                                <a [routerLink]="subLink?.url" *ngFor="let subLink of newsSubLinks"
                                    style="justify-content: center;font-size: 12px;">
                                    <span class="title">{{subLink.text}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="dropdown-content">
                            <a [routerLink]="subLink?.url" *ngFor="let subLink of newsSubLinks"
                                style="justify-content: center;">
                                <span class="title">{{subLink.text}}</span>
                            </a>
                        </div>
                    </li> -->
                    <li class="dropdown in-the-news">
                        <a class="dropbtn" routerLink="/about-hisense" target="_self">About Hisense</a>
                        <a (click)="aboutUsSubDropdownToggle = true" class="toggle-dropdown"
                            id="toggle-dropdown-tv-and-audio" aria-haspopup="true" aria-expanded="false"
                            aria-label="Toggle dropdown"></a>
                        <div [ngClass]="{'menu-in': aboutUsSubDropdownToggle}" class="dropdown-menu"
                            aria-labelledby="toggle-dropdown-tv-and-audio">
                            <button (click)="aboutUsSubDropdownToggle = false" class="mobile-back" type="button"
                                aria-label="Toggle sub navigation">
                                <span class="navbar-back-icon"></span>
                            </button>
                            <div class="section-title">About Hisense</div>
                            <div class="sub-menu ">
                                <a [routerLink]="subLink?.url" *ngFor="let subLink of aboutUsSubLinks" [ngClass]="{'d-none': subLink.mobileHide}"
                                    style="justify-content: center;">
                                    <span class="title">{{subLink.text}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="dropdown-content">
                            <a [routerLink]="subLink?.url" *ngFor="let subLink of aboutUsSubLinks"
                                style="justify-content: center;">
                                <span class="title">{{subLink.text}}</span>
                            </a>
                        </div>
                    </li>
                    <li class="mobile-only">
                        <a routerLink="/about-hisense/newsroom">Newsroom</a>
                    </li>
                    <li class="mobile-only">
                        <a routerLink="/about-hisense/tech-testimonials">Tech Testimonials</a>
                    </li>
                    <li class="">
                        <a routerLink="/support">Support</a>
                    </li>
                </ul>
            </div>
        </div>
        <button [ngClass]="{'active':mobileNavToggle,'home-page': isHomePage === '/'}" class="mobile-nav-toggle"
            type="button" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"
            (click)="mobileNavToggle = !mobileNavToggle">
            <span class="navbar-toggler-icon"></span>
        </button>
    </nav>
</header>