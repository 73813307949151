<div class="footer-links-container" style="padding-top: 50px !important;">
  <div class="footer-links">

    <ul class="col" *ngFor="let menu of navLinks">
      <li class="row" [ngClass]="{'show':menu.row}" style="max-height: 58px;">
        <a (click)="menu.row = menu.row ? false : true" class="accordion-toggle"></a>
        <dl>
          <dt><a [routerLink]="menu.url">{{menu.text}}</a></dt>
          <dd *ngFor="let subMenu of menu['sub-menu']"><a [routerLink]="subMenu.url">{{subMenu.text}}</a></dd>
        </dl>
      </li>
    </ul>

    <ul class="col">
      <!-- <li class="row">
          <dl>
            <dt><a routerLink="/FIFA-2022" target="">FIFA 2022</a></dt>
          </dl>
        </li> -->
      <li class="row">
        <dl>
          <dt><a routerLink="/super-brand-days" target="">Super Brand Days</a></dt>
        </dl>
      </li>
      <li class="row" [ngClass]="{'show':row1}" style="max-height: 58px;">
        <a class="accordion-toggle" (click)="row1 = !row1"></a>
        <dl>
          <dt><a routerLink="/about-hisense" target="">About Hisense</a></dt>
          <dd><a routerLink="/about-hisense/overview" target="">Overview</a></dd>
          <dd><a routerLink="/about-hisense/values" target="">Values</a></dd>
          <dd><a routerLink="/about-hisense/csr" target="">CSR</a></dd>
          <dd><a routerLink="/about-hisense/technology" target="">Technology</a></dd>
          <dd><a routerLink="/about-hisense/newsroom" target="">Newsroom</a></dd>
          <dd><a routerLink="/about-hisense/tech-testimonials" target="">Tech Testimonials</a></dd>
          <dd><a routerLink="/about-hisense/authorized-sellers" target="">Authorized Sellers</a></dd>
          <dd><a routerLink="/about-hisense/partners" target="">Our Partners</a></dd>
        </dl>
      </li>
      <li class="row" [ngClass]="{'show':row2}" style="max-height: 58px;">
        <a class="accordion-toggle" (click)="row2 = !row2"></a>
        <dl>
          <dt><a routerLink="/support" target="">Support</a></dt>
          <dd><a routerLink="/terms-and-conditions/" target="">Terms And Conditions</a></dd>
          <dd><a routerLink="/contact-us" target="">Contact Us</a></dd>
          <dd><a routerLink="/product-warranty-information" target="">Warranty Information</a></dd>
          <dd><a download href="assets/E-waste_Management.pdf" target="_blank">{{'E-WASTE MANAGEMENT' | titlecase}}</a></dd>
          <dd><a routerLink="/product-accessories" target="">Accessories</a></dd>
        </dl>
      </li>
    </ul>

    <ul class="col">
      <li class="row" [ngClass]="{'show':row3}" style="max-height: 58px;">
        <a class="accordion-toggle" (click)="row3 = !row3"></a>
        <dl>
          <dt><a target="">{{'STAY CONNECTED WITH Hisense' | titlecase}}</a></dt>
          <div style="display: flex;gap: 12px;padding: 0 15px 10px;">
            <dt><a style="min-height: auto;" class="social-btn fb" href="https://www.facebook.com/HisenseIndiaOfficial/"
                target="_blank" rel="noopener">Facebook</a></dt>
            <dt><a style="min-height: auto;" class="social-btn twitter" href="https://twitter.com/Hisense_IND"
                target="_blank" rel="noopener">Twitter</a></dt>
            <dt><a style="min-height: auto;" class="social-btn youtube"
                href="https://www.youtube.com/channel/UCuq2S-nKt0dL84SjoT-35YA" target="_blank"
                rel="noopener">Youtube</a></dt>
            <dt><a style="min-height: auto;" class="social-btn ig"
                href="https://www.instagram.com/hisenseindiaofficial/?hl=en" target="_blank"
                rel="noopener">Instagram</a></dt>
            <dt><a style="min-height: auto;" class="social-btn linkedin"
                href="https://www.linkedin.com/company/hisenseindia/" target="_blank" rel="noopener">Linkedin</a></dt>
          </div>
        </dl>
      </li>
    </ul>
  </div>
</div>

<div class="bottom-links-container">
  <div class="wrapper">
    <div>
      <a routerLink="/">
        <svg version="1.1" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="154px" height="26px" viewBox="0 0 100 17"
          style="enable-background:new 0 0 100 17;" xml:space="preserve">
          <style type="text/css">
            .logo {
              fill: #646464;
            }
          </style>
          <g>
            <path class="logo"
              d="M65.5,8.8L65,16.5h4.6l0.5-7.8c0.2-3.5-2.3-3.8-3.9-4c-0.1,0-1-0.1-2.2-0.1c-0.3,0-0.6,0-1,0
                      c-2.9,0.1-6.4,0.5-6.4,0.5l-0.1,0l-0.7,11.3h4.6L61,7.3c0,0,1.9-0.2,2.9-0.2C65,7.2,65.6,7.5,65.5,8.8">
            </path>
            <path class="logo" d="M13.7,0.3L13.7,0.3l-0.1,0.1L13.1,6H5.9l0.4-4.6c0-1-0.8-1-1.2-1c-0.1,0-0.3,0-0.3,0h0H1.4H1.3l0,0.1L0,16.3
                      l0,0.1h0.1H5h0.1l0-0.1l0.5-7h7.2l-0.5,7l0,0.1h0.1h4.8h0.1l0-0.1l1.2-15.9l0-0.1h-0.1H13.7z">
            </path>
            <polygon class="logo" points="20.3,4.7 20.2,4.7 20.2,4.8 19.3,16.4 19.2,16.4 19.4,16.4 24.1,16.4 24.2,16.4 24.2,16.4 25.1,4.8 
                      25.1,4.7 25,4.7 	"></polygon>
            <path class="logo" d="M24.3,0.3h-3.7h-0.1l0,0.1l-0.2,2.8l0,0.1h0.1h4.7h0.1l0-0.1c0,0,0.1-1.6,0.1-1.9C25.4,0.7,25.1,0.3,24.3,0.3z
                      "></path>
            <path class="logo"
              d="M35.2,9.5l-1.8-0.7c-1.2-0.5-2.2-0.9-2-1.4c0.1-0.3,0.7-0.5,2.4-0.5c1.5,0,3.2,0.2,5.2,0.6l0.1,0l0-0.1
                      l0.2-2.2l0-0.1l-0.1,0c-0.8-0.1-2.8-0.5-5.4-0.6c-0.2,0-0.3,0-0.5,0c-1,0-1.9,0.1-2.7,0.2c-1.6,0.2-4.2,0.9-4.4,3.1
                      c-0.1,2.3,2.6,3.2,4.2,3.9l1.4,0.5c1.6,0.6,2.3,0.9,2.3,1.3c0,0.4-0.6,0.5-2,0.5c-0.2,0-0.4,0-0.5,0c-1.8,0-4.3-0.4-5.2-0.6l-0.1,0
                      l0,0.1L26,16l0,0.1l0.1,0c0,0,1.9,0.3,5.8,0.4c0.2,0,0.5,0,0.7,0c2.9,0,6.4-0.4,6.8-2.8C39.8,11.5,37.5,10.5,35.2,9.5z">
            </path>
            <path class="logo"
              d="M54.8,11.5c1-5.6-4-6.7-5.2-6.9c-0.4-0.1-0.9-0.1-1.4-0.1l-0.1,0c-5,0.1-6.9,2.6-7.7,4.5
                      c-0.1,0.1-0.2,0.6-0.3,1.3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c-0.1,1.7,0.4,4.6,4.5,5.6c0.2,0.1,0.5,0.1,0.7,0.1
                      c0.9,0.1,1.5,0.3,3.2,0.3c2.8,0,4.7-0.4,5.4-0.6c0.1-0.5,0.3-1.6,0.4-2.4c-0.4,0.1-2.7,0.6-5.2,0.6c-0.1,0-0.2,0-0.2,0
                      c-3.5-0.1-4.2-1.8-4.2-2.6l0,0c0,0,0,0,0,0H54.8z M45,9.3c0.2-1.2,1.2-2.5,3.2-2.5c2.9,0,2.7,2.5,2.7,2.5H45z"></path>
            <path class="logo"
              d="M80.2,9.5l-1.8-0.7c-1.2-0.5-2.2-0.9-2-1.4c0.1-0.3,0.7-0.5,2.4-0.5c1.5,0,3.2,0.2,5.2,0.6l0.1,0l0-0.1
                      l0.2-2.2l0-0.1l-0.1,0c-0.8-0.1-2.8-0.5-5.4-0.6c-0.2,0-0.4,0-0.5,0c-1,0-1.9,0.1-2.7,0.2c-1.6,0.2-4.2,0.9-4.4,3.1
                      c-0.1,2.3,2.6,3.2,4.2,3.9l1.4,0.5c1.6,0.6,2.3,0.9,2.3,1.3c0,0.4-0.6,0.5-2,0.5c-0.2,0-0.4,0-0.5,0c-1.8,0-4.3-0.4-5.2-0.6l-0.1,0
                      l0,0.1L71,16l0,0.1l0.1,0c0,0,1.9,0.3,5.8,0.4c0.2,0,0.5,0,0.7,0c2.9,0,6.4-0.4,6.8-2.8C84.8,11.5,82.5,10.5,80.2,9.5z">
            </path>
            <path class="logo"
              d="M99.9,11.5c1-5.6-4-6.7-5.2-6.9c-0.4-0.1-0.9-0.1-1.4-0.1l-0.1,0c-5,0.1-6.9,2.6-7.7,4.5
                      c-0.1,0.1-0.2,0.6-0.3,1.3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c-0.1,1.7,0.4,4.6,4.5,5.6c0.2,0.1,0.5,0.1,0.7,0.1
                      c0.9,0.1,1.5,0.3,3.2,0.3c2.8,0,4.7-0.4,5.4-0.6c0.1-0.5,0.2-1.6,0.4-2.4c-0.4,0.1-2.7,0.6-5.2,0.6c-0.1,0-0.2,0-0.2,0
                      c-3.5-0.1-4.2-1.8-4.2-2.6l0,0c0,0,0,0,0,0H99.9z M90,9.3c0.2-1.2,1.2-2.5,3.2-2.5c2.9,0,2.7,2.5,2.7,2.5H90z"></path>
          </g>
        </svg>
      </a>
    </div>
    <div>
      <ul class="bottom-links">
        <li><a routerLink="/legal-disclaimer">Legal Disclaimer</a></li>
        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
        <li class="copyright">2022 © Copyright Hisense</li>
      </ul>
    </div>
  </div>
</div>