<!-- <div class="video-container about-container">
    <div>
        <div class="content">
            <h3>
                <p><strong>Hisense</strong> OFFICIAL SPONSOR of</p>

                <p>FIFA WORLD CUP 2022™</p>
            </h3>
            <a class="btn-video" (click)="playVideo = true">
                Play Video</a>
        </div>
        <div class="banner">
            <img class="lazy desktop-only" alt="Hisense FIFA WORLD CUP 2022" [src]="image1">
            <img class="lazy mobile-only" alt="Hisense FIFA WORLD CUP 2022" [src]="image2">
        </div>
    </div>
</div> -->

<!-- <div class="video-container about-container hisense__intro__video" style="padding-top: 0 !important;">
    <video controls style="max-height: 100%;width: 100%;background: #d5cfcf;">
        <source [src]="video1" type="video/mp4">
        Your browser does not support the video tag.
    </video>
</div> -->

<div class="video-container about-container hisense__intro__video" style="padding-top: 8px !important;margin-top: 10px;">
    <iframe width="100%" style="height: 100%" src="https://www.youtube.com/embed/sB8QBLmU4vE?si=KkKH3nr9_JfU4V09" title="Hisense India X Ravindra Jadeja | Perfecting The Perfection"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>

<div class="video-container about-container hisense__intro__video" style="padding-top: 0 !important;">
    <iframe width="100%" style="height: 100%" src="https://www.youtube.com/embed/YvMn9B53-FM?si=UZywdAeLTfYGDpmQ" title="2024 Hisense Group Intro"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>

<div class="video-container about-container hisense__intro__video" style="padding-top: 0 !important;">
    <iframe width="100%" style="height: 100%" src="https://www.youtube.com/embed/j_m9bkytuOU?si=fW9dZhnWqoo0Q6dN" title="Dolby X Hisense- 101"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>
<!-- <div class="video-container">
    <div>
        <div class="content">
            <h3>
            </h3>
            <a class="btn-video" (click)="playVideo1 = true">
                Play Video</a>
        </div>
        <div class="banner">
            <img class="lazy" alt="Hisense AC" [src]="image3">
        </div>
    </div>
</div> -->



<!-- <ng-container *ngIf="playVideo">
    <div class="mfp-bg video-lightbox mfp-ready"></div>
    <div class="mfp-wrap mfp-auto-cursor video-lightbox mfp-ready" tabindex="-1" style="overflow: hidden auto;">
        <div class="mfp-container mfp-s-ready mfp-inline-holder">
            <div class="mfp-content">
                <div class="video-wrap">
                    <video autoplay controls>
                        <source [src]="video1" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <button type="button" class="close" (click)="playVideo = false">Close</button>
            </div>
            <div class="mfp-preloader">Loading...</div>
        </div>
    </div>
</ng-container> -->

<!-- <ng-container *ngIf="playVideo1">
    <div class="mfp-bg video-lightbox mfp-ready"></div>
    <div class="mfp-wrap mfp-auto-cursor video-lightbox mfp-ready" tabindex="-1" style="overflow: hidden auto;">
        <div class="mfp-container mfp-s-ready mfp-inline-holder">
            <div class="mfp-content">
                <div class="video-wrap">
                    <video autoplay controls>
                        <source src="assets/home_page/hisense-opt-14.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <button type="button" class="close" (click)="playVideo1 = false">Close</button>
            </div>
            <div class="mfp-preloader">Loading...</div>
        </div>
    </div>
</ng-container> -->