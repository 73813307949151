<div class="page-wrapper home__page">
    <div class="main-container" style="margin-bottom: -10px;">
        <h1 class="visuallyhidden">Hisense Global Website</h1>
        <div style="overflow: hidden;">
            <app-main-slider [slides]="slides"></app-main-slider>
        </div>
        <app-series></app-series>
        <app-about></app-about>
        <!-- <app-customer-testimonials *ngIf="customerTestimonials" [customerTestimonials]="customerTestimonials"></app-customer-testimonials> -->
        <!-- <app-home-products></app-home-products> -->
        <app-marketing-video></app-marketing-video>
        <!-- <app-subscribe-now></app-subscribe-now> -->
    </div>
</div>