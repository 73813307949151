<div class="main-visual slider slick-initialized slick-slider slick-dotted" style="margin-bottom: 100px; overflow: visible !important;">
    <div class="slick-list draggable" style="overflow: visible !important;">
        <div class="slick-track">
            <div class="swiper mySwiperHome" style="overflow: visible !important;">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let slide of slides;let i = index">
                        <a style="width: 100%;" class="slide__link" [href]="slide.click_link">
                            <picture>
                                <source media="(min-width:768px)" [srcset]="slide.desktop_image_full_url">
                                <img [src]="slide.mobile_image_full_url" style="width: 100%;" class="banner"
                                    class="ls-bg" [alt]="'Home page slide ' + (i + 1)">
                            </picture>
                        </a>
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</div>