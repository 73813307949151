<div class="email-subscription-container">
    <div class="content">
        <!-- action="https://mirumagency.us1.list-manage.com/subscribe/post?u=fee051a00ffa12b70551237a9&amp;amp;id=202c45b592"
        method="post" id="mc-embedded-homepage-subscribe-form" name="mc-embedded-homepage-subscribe-form" -->
        <form>
            <h3>Stay up to date with</h3>
            <img class="logo lazy" src="assets/hisense-white.png"
                alt="Hisense">
            <div class="input-container">
                <input placeholder="Your email address" type="email" value="" name="EMAIL" required="" [(ngModel)]="email">
                <button class="btn-subscribe cursor-pointer" (click)="subscribeNow()">Subscribe Now</button>
            </div>
            <p class="form-message success hidden">Thank you for subscribing!</p>
            <p class="form-message error hidden">There was an error..</p>
        </form>
    </div>
</div>

<div class="modal" (click)="subscribedSuccessModal = false" [ngClass]="{'show-modal': subscribedSuccessModal}">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-container">
            <img src="assets/images/success.png" alt="" width="100" height="100">
            <div>Subscription Succesful!</div>
        </div>
        <button (click)="subscribedSuccessModal = false" class="js-close">Dismiss</button>
    </div>
</div>