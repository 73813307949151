import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-links-footer',
  templateUrl: './social-links-footer.component.html',
  styleUrls: ['./social-links-footer.component.scss']
})
export class SocialLinksFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
